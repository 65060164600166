function Footer() {
  return (
    <div className="footer">
      <p className="subtitle">IRR Engineering S.r.l</p>
      <div className="location">
        <p>Piazza Velasca 4</p>
        <p>20122 Milano</p>
        <p>Italia</p>
      </div>
      <div className="contacts">
        <p>T. 02 12345678 // 02 87654321</p>
        <p>www.irrengineering.it</p>
      </div>
    </div>
  );
}

export default Footer;
